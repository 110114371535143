@charset "UTF-8";


/*폰트*/
.txt-sm {
    font-size: 12px !important;
    line-height: 18px;
}
.txt-md {
    font-size: 16px !important;
}
.point-txt-pink {
    color: #ff3477 !important;
}
.point-txt-green {
    color: #12b469 !important;
}
.point-txt-gray {
    color: #999da4 !important;
}
.point-txt-lgray {
    color: rgba(153, 157, 164, 0.6) !important;
}
.font-100 {font-weight: 100 !important;} 
.font-400 {font-weight: 400 !important;}
.font-700 {font-weight: 700 !important;}

/*정렬*/
.al-left {text-align: left !important;}
.al-center {text-align: center !important;}
.al-right {text-align: right !important;}


/*버튼*/
.btn {
    color: #fff;
    border-radius: 4px;
    font-weight: 100;
    padding: 0;
}
.btn-lg {    
    font-size: 16px;
    height: 54px;
    line-height: 52px;
}
.btn-md {
    font-size: 14px;
    height: 40px;
    line-height: 38px;
}
.btn-sm {
    font-size: 14px;
    height: 30px;
    line-height: 26px;
}
.btn-flex {
    display: flex;
    justify-content: space-between;
}
.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:visited {
    background: #ff3477;
    border-color: #ff3477;
}
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:visited {
    background: #541b86;
    border-color: #541b86;
}
.btn-gray, 
.btn-gray:hover,
.btn-gray:active,
.btn-gray:focus,
.btn-gray:visited {
    background: #999da4;
    border-color: #999da4;
}
.btn-white,
.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white:visited {
    background: #fff;
    border-color: #fff;
    color: #999da4;
}
.btn-outline-primary,
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary:visited {
    color: #ff3477;
    border-color: #ff3477;
    background: #fff;
}
.btn-outline-secondary,
.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary:visited {
    color: #541b86;
    border-color: #541b86;
    background: #fff;
}
.btn-outline-gray,
.btn-outline-gray:hover,
.btn-outline-gray:focus,
.btn-outline-gray:active,
.btn-outline-gray:visited {
    color: #999;
    border-color: rgba(125,128,133,.6);
    background: #fff;
}
.btn-outline-black,
.btn-outline-black:hover,
.btn-outline-black:focus,
.btn-outline-black:active,
.btn-outline-black:visited {
    color: #1d1d1d;
    border-color: #1d1d1d;
    background: #fff;
}
.btn.disabled, 
.btn:disabled {
    color: #aaa;
    background-color: #eee; 
    border-color: #eee;
}
/*뱃지*/
.bedge {
    display: inline-block;
    vertical-align: middle;
    height: 18px;
    font-size: 0;
    margin-left: 5px;
}
.bedge img {
    height: 18px;
    object-fit: contain;
    object-position: left;
}

/*모달팝업*/
.offcanvas {
    left: 50%;
    width: 100%;
    max-width: 420px;
    border-radius: 20px 20px 0 0;
    padding-top:16px;
}
.offcanvas.show {
    transform: translate(-50%, 0);
}
.offcanvas-bottom {
    height: auto;
    transform: translate(-50%, 100%);
}
.offcanvas-header {
    padding: 0 16px;
}
.offcanvas-title {
    width: 100%;
    text-align:center;
}
.offcanvas-body {
    padding: 0 16px 20px 16px;
}
.offcanvas-body h3+p {
    margin-top: 6px;
}

.modal {
    background: rgba(29, 29, 29, .8);
}
.modal-dialog-centered {
    justify-content: center;
}
.modal-content {
    border-radius: 12px;
}
.modal-alarm {
    width: 300px;    
    border:0;
    text-align: center;
    padding: 47px 0 0 0;
}
.modal-alarm::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 62px;
    height: 62px;
    background: #ff3477 url(../images/ic_access_noti.svg) center center no-repeat;
    border-radius: 50%;
    margin-top: -31px;
}
.modal-body {
    padding: 16px;
    font-size: 16px;
    line-height: 22px;
}
.modal-btn {
    display: flex;
    flex-wrap: nowrap;
    border: 0;
    padding: 16px;
}
.modal-btn button {
    width: calc(50% - 5px);
    font-size: 14px;
}
.modal-btn button+button {
    margin-left: 10px;
}
.modal-content {border-radius: 0; border:0;}
.modal-content .header {
    position: sticky;
    transform: translate(0, 0);
}
@media(min-width: 576px) {
    .modal-dialog {
        max-width: 420px;
        margin: 100px auto;
    }
}
.backDrop {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.3);
}

.switch {
    width: 40px;
    height: 24px;
    text-align: center;
}
.switch input {
position: absolute;
/* hidden */
appearance: none;
-webkit-appearance: none;
-moz-appearance: none;
}

.switch_label {
position: relative;
cursor: pointer;
display: inline-block;
width: 40px;
height: 24px;
background: #ddd;
border-radius: 20px;
transition: 0.2s;
}
.onf_btn {
position: absolute;
top: 3px;
left: 3px;
display: inline-block;
width: 18px;
height: 18px;
border-radius: 18px;
background: #fff;
opacity: 0.8;
transition: 0.2s;
}
/* checking style */
.switch input:checked+.switch_label {
background: #ff3477;
}

.switch input:checked+.switch_label:hover {
background: #ff3477;
}

/* move */
.switch input:checked+.switch_label .onf_btn {
left: 20px;
}

/* 아코디언 */
.accordion-item {    
border: 1px solid rgba(0,0,0,.1);
border-top: 1px solid rgba(0,0,0,.1) !important;
border-bottom: 0 !important;
border-left: 0 !important;
border-right: 0 !important;
}
.accordion-header {
    padding: 20px 0;
}
.accordion-button {
    display: block;
    padding: 0;
}
.accordion-button>p {
    font-weight: 400;
    line-height: 21px;
    padding-right: 20px;
}
.accordion-button>span {
display: block;
font-weight: 400;
margin-top: 8px;
}
.accordion-button::after {
position: absolute;
top: 0;
right: 0;
width: 16px;
height: 16px;
background-image: url(../images/ic_arrow_dropdown.svg);
background-size: 16px;
}
.accordion-button:not(.collapsed)::after {
    background-image: url(../images/ic_arrow_dropdown.svg);
    transform: rotate(-180deg);
}
.accordion-button:not(.collapsed) {
    color: #1d1d1d;
    background-color: #fff;
    box-shadow: unset;
}
.accordion-body {
    padding: 0;
    line-height: 22px;
    color: #484848;
    padding-bottom: 24px;
}
/* 라벨 */
.status {
    position: relative;
    padding-left: 12px;
}
.status::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
}
.online {color: #008e39 !important;}
.online::before {
    background: #008e39;
}
.offline {color: #999da4 !important;}
.offline::before {
    background: #999da4;
}
.booking {color: #ff3477 !important;}
.booking::before {
    background: #ff3477;
}

/*탭메뉴*/
.tab-menu {
    display: flex;
}
.tab-menu button {
    flex: 1;
    color: #999da4;
    height: 46px;
    border-bottom: solid 1px #e1e1e1;
}
.tab-menu button.on {
    color: #1d1d1d;
    border-bottom: solid 2px #ff3477;
}
.header .tab-menu {
    margin: 0 -16px;
}

.message-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.message-box p {
    color: rgba(29, 29, 29, 0.3);
}
.txt-link-arrow {
    font-size: 12px;
    color: #999da4;
}
.txt-link-arrow::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    background: url(../images/ic_arrow_link.svg) no-repeat;
    background-size: 100%;
    margin-left: 3px;
    margin-bottom: 2px;
}

/** check&radio **/
.input_rdo, 
.input_chk {
    position: relative;
    font-size:inherit;
    height: 24px;
    overflow: hidden;
    cursor: pointer;
}
.input_rdo input,
.input_chk input {
    position: absolute;
    top: 0;
    padding: 0;
    margin: 0; 
    width: 24px;
    height: 24px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    opacity: 0;
}
.input_rdo span,
.input_chk span {
    font-weight: 400;
    line-height: 24px;
}
.input_rdo i,
.input_chk i {
    padding-left: 32px;
    font-style: normal;
    line-height: 24px;
}
.input_rdo i:before, .input_rdo i:after,
.input_chk i:before, .input_chk i:after {
    position: absolute;
}
.input_rdo i:before,
.input_chk i:before {
    content: '';
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background: #ddd;
}
.input_rdo i:before {
    border-radius: 100%;
}
.input_chk input + i:after {
    content: '';
    width: 12px;
    height: 7px;
    top: 6px;
    left: 6px;
    border-left: 2px solid #bbb;
    border-bottom: 2px solid #bbb;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
}
.input_chk input:checked + i:before {
    background: #ff3477;
}
.input_chk input:checked + i:after {
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
}
.input_rdo input:checked + i:before {
    background: rgba(255,52,119,0.6);
}
.input_rdo input + i:after {
    content: '';
    top: 6px;
    left: 6px;
    width: 12px;
    height: 12px;
    background: #bbb;
    border-radius: 100%;
}
.input_rdo input:checked + i:after {
    background: #ff3477;
}
.input_rdo input:disabled + i,
.input_chk input:disabled + i {
    cursor: default;
}
.input_chk input:disabled + i:before,
.input_chk input:disabled:checked + i:before {
    opacity: 0.4;
    background-color: #ddd;
}
.input_rdo input:disabled + i:before,
.input_rdo input:disabled:checked + i:before {
    opacity: 0.4;
}
.input_rdo input:disabled + i:after,
.input_rdo input:disabled:checked + i:after {
    opacity: 0.4;
}
