@charset "UTF-8";

.wrapper {
  max-width: 420px;
  margin: 0px auto;
  background-color: white;
}
.container {
  position: relative;
  padding: 46px 24px 80px 24px;
  min-height: 100vh;
}

/* header------s*/
.header {
  position: fixed;
  left: 50%;
  top: 0;
  z-index: 10;
  max-width: 420px;
  width: 100%;
  height: 46px;
  text-align: center;
  padding: 0 16px;
  transform: translateX(-50%);
  background: #fff;
}
.header h3 {
  width: 100%;
  line-height: 46px;
}
.scrollNav {
  background: transparent;
}
.scrollNav > h3 {
  display: none;
}
.header.home {
  background: rgb(230, 0, 68);
  background: linear-gradient(
    90deg,
    rgba(230, 0, 68, 1) 0%,
    rgba(101, 31, 162, 1) 100%
  );
}
.header.home h3 {
  font-size: 0;
  width: 90px;
  position: relative;
  margin: 12px auto;
  background: url(../images/logo.svg) no-repeat;
}
.header-icon {
  position: absolute;
  left: 16px;
  top: 11px;
  width: 24px;
  height: 24px;
  font-size: 0;
}
.header-icon.ic-close {
  background: url(../images/ic_close.svg) no-repeat;
  background-size: 100%;
}
.header-icon.ic-back {
  background: url(../images/ic_arrow_back.svg) no-repeat;
  background-size: 100%;
}
.scrollNav .header-icon.ic-back {
  background: url(../images/ic_arrow_back_w.svg) no-repeat;
  background-size: 100%;
}
.header-icon.ic-share {
  left: unset;
  right: 60px;
  background: url(../images/ic_share.svg) no-repeat;
  background-size: 100%;
}
.scrollNav .header-icon.ic-share {
  background: url(../images/ic_share_w.svg) no-repeat;
  background-size: 100%;
}
.header-icon.ic-heart {
  left: unset;
  right: 16px;
  background: url(../images/ic_heart_line.svg) no-repeat;
  background-size: 100%;
}
.scrollNav .header-icon.ic-heart {
  background: url(../images/ic_heart_line_w.svg) no-repeat;
  background-size: 100%;
}
.header-icon.ic-heart.on {
  background: url(../images/ic_heart_pink.svg) no-repeat;
  background-size: 100%;
}
/* header------e*/

/* nav-bar------s*/
.nav-bar {
  position: fixed;
  z-index: 10;
  left: 50%;
  bottom: 0;
  width: 100%;
  max-width: 420px;
  height: 48px;
  background: #f2f4f7;
  transform: translateX(-50%);
  border-top: solid 1px #e1e1e1;
}
.nav-bar ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-bar li {
  max-width: 144px;
  min-width: 72px;
  text-align: center;
}
.nav-bar li a {
  display: block;
  padding: 8px 0;
}
.nav-bar li a i {
  display: inline-block;
  width: 24px;
  height: 24px;
}
.nav-bar li a em {
  display: block;
  font-size: 10px;
  color: #999da4;
  font-style: normal;
}
.nav-bar li a.focus em {
  color: #ff3477;
}
.icon-home {
  background: url(../images/ic_menu_home.svg) no-repeat;
  background-size: 100%;
}
.icon-search {
  background: url(../images/ic_menu_search.svg) no-repeat;
  background-size: 100%;
}
.icon-coin {
  background: url(../images/ic_menu_coinshop.svg) no-repeat;
  background-size: 100%;
}
.icon-MY {
  background: url(../images/ic_menu_people.svg) no-repeat;
  background-size: 100%;
}
.icon-more {
  background: url(../images/ic_menu_more.svg) no-repeat;
  background-size: 100%;
}
.nav-bar li a.focus .icon-home {
  background: url(../images/ic_menu_home_on.svg) no-repeat;
  background-size: 100%;
}
.nav-bar li a.focus .icon-search {
  background: url(../images/ic_menu_search_on.svg) no-repeat;
  background-size: 100%;
}
.nav-bar li a.focus .icon-coin {
  background: url(../images/ic_menu_coinshop_on.svg) no-repeat;
  background-size: 100%;
}
.nav-bar li a.focus .icon-MY {
  background: url(../images/ic_menu_people_on.svg) no-repeat;
  background-size: 100%;
}
.nav-bar li a.focus .icon-more {
  background: url(../images/ic_menu_more_on.svg) no-repeat;
  background-size: 100%;
}
/* nav-bar------e*/

/* home------s */
.get-point {
  position: relative;
  padding: 10px 0 0 0;
}
.get-point::after {
  content: '';
  display: block;
  height: 1px;
  background: #ddd;
  margin: 10px -16px 0 -16px;
}
.get-point a {
  position: absolute;
  right: 0;
}
.get-point a::after {
  content: '';
  display: inline-block;
  vertical-align: top;
  width: 16px;
  height: 16px;
  background: url(../images/ic_arrow_link.svg) no-repeat;
  background-size: 16px;
  margin-left: 4px;
}
.category-group {
  margin-top: 30px;
}
.category {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}
.category > button {
  position: relative;
  width: 80px;
  text-align: center;
}
.category > button::before {
  content: '';
  display: block;
  height: 40px;
  margin-bottom: 12px;
}
.category > button:nth-child(1):before {
  background: url(../images/ic_tarot.svg) center top no-repeat;
}
.category > button:nth-child(2):before {
  background: url(../images/ic_shaman.svg) center top no-repeat;
}
.category > button:nth-child(3):before {
  background: url(../images/ic_astrology.svg) center top no-repeat;
}
.category > button:nth-child(4):before {
  background: url(../images/ic_therapy.svg) center top no-repeat;
}
.category > button::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 40px;
  right: 0;
  top: 0;
  background: #eee;
}
.category > button:last-child:after {
  display: none;
}
h3 + .gallery-group {
  padding-top: 12px;
}
.gallery-group {
  padding-top: 24px;
}
.gallery-group .btn-more {
  color: #484848;
  font-size: 14px;
  font-weight: 400;
  height: 42px;
  border: solid 1px rgba(125, 128, 133, 0.3);
}
.gallery-group .btn-more::after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  transform: rotate(90deg);
  background: url(../images/ic_arrow_link2.svg) no-repeat;
  margin-left: 5px;
  margin-bottom: 3px;
}
.gallery-group .btn-all {
  color: #484848;
  font-size: 14px;
  font-weight: 400;
  height: 42px;
  border: solid 1px rgba(125, 128, 133, 0.3);
}
.gallery-group .btn-all::after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background: url(../images/ic_arrow_link2.svg) no-repeat;
  margin-left: 5px;
  margin-bottom: 3px;
}
.gallery-block {
  position: relative;
}
.gallery-block > h4 {
  font-size: 16px;
  font-weight: 400;
}
.gallery-block .more {
  position: absolute;
  right: 0;
  top: 5px;
}
.gallery-block > h3,
.gallery-block > h4 {
  margin-bottom: 10px;
}
.gallery-block ul {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
}
.gallery-block li {
  position: relative;
  width: 50%;
  margin-bottom: 12px;
  padding-left: 8px;
}
.gallery-block li .favorite {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  width: 38px;
  height: 38px;
  font-size: 0;
  background: url(../images/ic_heart_line_w.svg) center center no-repeat;
  background-size: 18px;
}
.gallery-block li .favorite.on {
  background: url(../images/ic_heart_pink.svg) center center no-repeat;
  background-size: 18px;
}
.gallery-block li img {
  border-radius: 4px;
}
.gallery-block li span {
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  color: #484848;
  margin-right: 10px;
}
.gallery-block li span.status_off {
  color: #e81649;
}
.gallery-block li span.status_off::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  background: #e81649;
  border-radius: 50%;
  margin-right: 2px;
}
.gallery-block li span.status_on {
  color: #008e39;
}
.gallery-block li span.status_on::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  background: #008e39;
  border-radius: 50%;
  margin-right: 2px;
}
.gallery-block li span.conut-fv {
  padding-left: 10px;
  background: url(../images/ic_star.svg) left center no-repeat;
  background-size: 10px;
}
.hashtag {
  text-align: center;
  padding: 16px;
  margin: 32px -16px 32px -16px;
  background: rgb(230, 0, 68);
  background: linear-gradient(
    90deg,
    rgba(230, 0, 68, 1) 0%,
    rgba(101, 31, 162, 1) 100%
  );
}
.hashtag h3 {
  color: #fff;
  margin-bottom: 12px;
}
.hashtag a {
  display: inline-block;
  vertical-align: middle;
  line-height: 32px;
  color: #fff;
  padding: 0 15px;
  border-radius: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 5px 3px;
}
.home-wrap .slide {
  margin-top: 32px;
}
.home-wrap .carousel-indicators {
  position: absolute;
  bottom: 0;
  right: 10px;
  display: block;
  width: auto;
  left: unset;
  margin: 0;
}
.home-wrap .carousel-indicators button {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-left: 1px;
  margin-right: 0;
}

.sort-group {
  position: relative;
  margin-top: 10px;
}
.btn-sort {
  position: absolute;
  right: 0;
}
.btn-sort::after {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url(../images/ic_arrow_dropdown.svg) no-repeat;
  background-size: 12px;
}
.event-wrap {
  border-radius: 14px;
  overflow: hidden;
}
.access_popup .title-group {
  text-align: center;
  font-size: 14px;
}
.access_popup h4 {
  margin-top: 20px;
}
.access_popup p {
  margin-top: 8px;
}
.access_popup dl {
  position: relative;
  padding: 8px 0 0 52px;
  margin-bottom: 12px;
}
.access_popup dl::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
}
.access_popup dl.call::before {
  background: url(../images/ic_access_call.svg) center center no-repeat;
  background-size: 40px;
}
.access_popup dl.noti::before {
  background: url(../images/ic_access_noti.svg) center center no-repeat;
  background-size: 40px;
}
.access_popup dt {
  font-weight: 400;
  font-size: 14px;
}
.access_popup .comment {
  line-height: 18px;
  margin: 20px 0;
}
/* home------e */

/* master------s */
.master .container {
  padding: 0;
}
.master .contents {
  padding: 0;
}
.master-wrap {
  padding-bottom: 70px;
}
.master-photo {
  position: relative;
}
.master-photo .bg-gra {
  position: absolute;
  z-index: 3;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 37px;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}
.master-photo .slide {
  padding-top: 0;
  height: 480px;
  overflow: hidden;
}
.master-photo .btn-play {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 58px;
  height: 58px;
  background: url(../images/ic_video_play.svg) no-repeat;
  background-size: 100%;
  font-size: 0;
}
.carousel-indicators {
  margin-left: 16px;
  margin-right: 16px;
}
.carousel-indicators [data-bs-target] {
  width: 25%;
}
.master-detail {
  padding: 16px;
}
.master-detail > h1 + pre {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 14px;
}
.master-info {
  padding-top: 14px;
}
.master-info > li {
  padding: 0 0 0 18px;
  margin-top: 15px;
}
.master-info li a {
  color: #297ccb;
}
.master-info > li:nth-child(1) {
  background: url(../images/ic_call.svg) no-repeat;
  background-size: 12px;
}
.master-info > li:nth-child(2) {
  background: url(../images/ic_check_gray.svg) no-repeat;
  background-size: 12px;
}
.master-info > li:nth-child(3) {
  background: url(../images/ic_star_empty.svg) no-repeat;
  background-size: 12px;
}
.introduce {
  color: #484848;
  line-height: 22px;
  padding: 20px 0;
  border-top: solid 1px #eee;
  margin-top: 16px;
}
.introduce .title {
  font-size: 12px;
  color: #999da4;
  margin-bottom: 12px;
}
.introduce ul {
  margin-top: 20px;
}
.reivew-info {
  padding: 20px 0;
  border-top: solid 1px #eee;
  margin-top: 16px;
}
.reivew-info > h2 {
  margin-bottom: 16px;
}
.reivew-info dl {
  display: flex;
}
.reivew-info dt {
  width: 115px;
  font-weight: 100;
}
.reivew-info dt .count {
  display: block;
  font-size: 56px;
  font-weight: 400;
  line-height: 83px;
  margin-bottom: 12px;
}
.reivew-info dt a::after {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 3px;
  background: url(../images/ic_arrow_link.svg) no-repeat;
  background-size: 12px;
}
.reivew-info dd {
  width: calc(100% - 115px);
  text-align: right;
  padding-top: 8px;
}
.reivew-info dd span {
  display: inline-block;
  vertical-align: middle;
}
.reivew-info dd .star-img {
  width: 68px;
}
.reivew-info dd .graphBar {
  width: calc(100% - 72px);
  height: 6px;
  background: rgba(153, 157, 164, 0.3);
  border-radius: 6px;
}
.reivew-info dd .graphBar > span {
  display: block;
  height: 6px;
  background: #d91743;
  border-radius: 6px;
}
.reivew-info dd p + span {
  margin-top: 8px;
}
.sort-btn {
  text-align: right;
  margin-top: 30px;
  padding-bottom: 8px;
}
.sort-btn button {
  font-size: 12px;
  color: #999da4;
  padding: 0;
}
.sort-btn button.on {
  color: #1d1d1d;
}
.sort-btn button + button {
  margin-left: 12px;
}

.review-list {
  position: relative;
}
.txt-more {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #ff3477;
}
.review-list li {
  position: relative;
  padding: 8px 0 16px;
  border-top: solid 1px #e1e1e1;
  text-align: left;
}
.review-list li p,
.review-list li pre {
  color: #484848;
  line-height: 22px;
}
.review-list li .review-star {
  display: inline-block;
}
.review-list li .user {
  font-size: 12px;
  color: #999da4;
  margin: 10px 0;
}
.review-list li .user span {
  display: inline-block;
  margin-right: 10px;
}
.review-list li .getHelp {
  position: absolute;
  right: 0;
  top: 5px;
  font-size: 12px;
  color: rgba(153, 157, 164, 0.6);
  padding-top: 30px;
  width: 59px;
  height: 59px;
  background: url(../images/btn_thumbs_up.svg) no-repeat;
  background-size: 59px;
}
.review-list li .getHelp.on {
  color: #ff3477;
  background: url(../images/btn_thumbs_up_on.svg) no-repeat;
  background-size: 59px;
}
.applyBar {
  position: fixed;
  left: 50%;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 420px;
  height: 56px;
  background: #fff;
  padding: 8px 16px;
  -webkit-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
  transform: translateX(-50%);
}
.applyBar .review-star {
  display: inline-block;
}
.applyBar .review-star span {
  width: 12px;
  height: 12px;
}
.applyBar .review-star .total {
  font-size: 12px;
  background: none;
}
.applyBar button {
  position: absolute;
  right: 16px;
  bottom: 8px;
}
.review-nodata {
  line-height: 22px;
  text-align: center;
  background: rgba(153, 157, 164, 0.1);
  padding: 30px 0;
}
.master-name {
  height: 62px;
}
.master-sum {
  position: absolute;
  right: 14px;
  top: 14px;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  overflow: hidden;
}
.myCoin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0 24px 0;
}
.myCoin span {
  margin-right: 12px;
}
.myCoin strong::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background: url(../images/ic_coin.svg);
  margin-right: 6px;
  margin-bottom: 3px;
}
.myCoin strong {
  font-size: 14px;
  color: #1d1d1d;
}
.after-pay {
  margin-top: 24px;
  padding-top: 16px;
  border-top: solid 1px #e1e1e1;
}
.after-pay .d-grid {
  margin: 20px 0 24px;
}
.login-comment {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin: 16px 0 24px 0;
}
/* master------e */

/* search------s*/
.search button + .search-box {
  position: absolute;
  left: 52px;
  top: 0;
  width: calc(100% - 68px);
}
.search-box {
  position: relative;
  width: 100%;
  height: 40px;
  background: rgba(153, 157, 164, 0.1);
  margin-top: 8px;
}
.search-box input {
  width: 100%;
  height: 40px;
  background: url(../images/ic_search.svg) left 8px center no-repeat;
  background-size: 20px;
  padding-left: 36px;
}
.search-box .src-cancel {
  display: none;
}
.focus .src-cancel {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 46px;
  height: 40px;
  background: #fff;
}
.focus input {
  padding-right: 46px;
}
.search-wrap .nokeyword-ment {
  text-align: center;
  padding-top: 100px;
  color: rgba(153, 157, 164, 0.6) !important;
}
.search-head {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  color: #999da4;
  padding: 12px 0 4px 0;
}
.search-head button {
  font-size: 0;
  position: absolute;
  right: 0;
  top: 12px;
  font-size: 12px;
  color: #999da4;
}
.search-list li {
  position: relative;
  padding: 13px 32px 13px 8px;
  border-bottom: solid 1px #eee;
}
.search-list li > p {
  display: block;
  width: 100%;
  line-height: 22px;
}
.search-list li .src-delete {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  font-size: 0;
  background: url(../images/ic_delete.svg) no-repeat;
  background-size: 16px;
}
.src-guide {
  font-size: 12px;
  color: #999da4;
  margin-top: 10px;
}
.src-guide::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 4px;
  height: 4px;
  background: #999da4;
  border-radius: 50%;
  margin-right: 5px;
}
.sort-head {
  text-align: center;
  padding: 8px 0;
  margin-top: 5px;
}
.sort-head input[type='radio'] {
  display: none;
}
.sort-head input[type='radio'] + label {
  display: inline-block;
  cursor: pointer;
  width: auto;
  line-height: 12px;
  font-size: 12px;
  color: #999da4;
  margin: 0 10px;
}
.sort-head input[type='radio'] + label::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 3px;
  height: 3px;
  background: #999da4;
  border-radius: 50%;
  margin-right: 5px;
}
.sort-head input[type='radio']:checked + label::before {
  width: 12px;
  height: 12px;
  background: url(../images/ic_check.svg) no-repeat;
  background-size: 12px;
}
/* search------e*/

/* review------s*/
.review-wrap {
  width: 100%;
  height: calc(100vh - 46px);
}
.review-wrap .d-grid {
  margin-top: 20px;
}
.review-check {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}
.review-check h2 {
  font-weight: 400;
  line-height: 32px;
}
.star-group {
  margin: 24px 0;
  text-align: center;
}
.star input[type='checkbox'] {
  display: none;
}
.star input[type='checkbox'] + label {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  font-size: 0;
}
.star input[type='checkbox'] + label::before {
  content: '';
  display: inline-block;
  width: 42px;
  height: 42px;
  background: url(../images/ic_star_empty.svg) no-repeat;
  background-size: 100%;
}
.star input[type='checkbox']:checked + label::before {
  background: url(../images/ic_star.svg) no-repeat;
  background-size: 100%;
}
.review-result {
  text-align: center;
  padding-top: 12px;
}
.review-result .star-group {
  margin: 12px 0;
}
.review-result .star-group .star input[type='checkbox'] + label::before {
  width: 18px;
  height: 18px;
}
.review-star {
  text-align: center;
  margin-top: 6px;
}
.review-star span {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  font-size: 0;
  background: url(../images/ic_star_empty.svg) no-repeat;
  background-size: 100%;
}
.review-star span.on {
  background: url(../images/ic_star.svg) no-repeat;
  background-size: 100%;
}
.textarea {
  background: rgba(153, 157, 164, 0.1);
  padding: 16px 14px 28px 14px;
  margin: 20px 0 6px 0;
}
.textarea textarea {
  background: transparent;
  width: 100%;
  height: 94px;
  resize: none;
  border: 0;
}
.textarea span {
  position: absolute;
  right: 14px;
  bottom: 6px;
}
.review-Policy {
  padding: 30px 0;
  text-align: center;
  border-bottom: solid 1px #eee;
}
.review-Policy h2::before {
  content: '';
  display: block;
  height: 12px;
  background: url(../images/ic_starGroup.svg) top center no-repeat;
  margin-bottom: 10px;
}
.review-Policy h2 > span {
  position: relative;
  display: inline-block;
}
.review-Policy h2 > span::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 150px;
  height: 16px;
  background: rgba(255, 52, 119, 0.3);
}
.policy-guide {
  padding: 25px 0;
  border-bottom: solid 1px #eee;
}
.policy-guide h3 {
  margin-bottom: 10px;
}
.policy-guide ol {
  margin: 12px 0;
}
.policy-guide li {
  line-height: 22px;
}
.policy-guide li::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 5px;
  height: 5px;
  background: #1d1d1d;
  border-radius: 50%;
  margin-right: 5px;
}
.policy-caution {
  padding: 25px 0;
  color: #999da4;
  font-size: 12px;
  line-height: 18px;
}
.policy-caution h4 {
  color: #999da4;
  font-size: 12px;
  font-weight: 400;
}
.policy-caution dl {
  margin-top: 16px;
}
.policy-caution dt {
  font-weight: 400;
}
/* review------e*/

/* more------s*/
.login-btn {
  padding: 24px 0 16px 0;
  text-align: center;
  border-bottom: solid 1px #e0e1e3;
}
.login-btn p {
  font-size: 14px;
  list-style: 22px;
  margin-bottom: 8px;
}
.menu-list li:last-child {
  border-bottom: 0;
}
.menu-list li {
  position: relative;
  border-bottom: solid 1px #e0e1e3;
}
.menu-list li > p {
  font-size: 14px;
  font-weight: 400;
  padding: 20px 0;
}
.menu-list li > a {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  background: url(../images/ic_arrow_link.svg) right center no-repeat;
  padding: 20px 0;
}
.menu-list li > p > span:last-child {
  position: absolute;
  right: 0;
}
.menu-list li > div {
  margin-top: 16px;
}
.menu-list li > div > span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 7px;
}
.menu-list li > div > span:last-child {
  font-weight: 400;
  color: #999;
  opacity: 0.6;
}
.menu-list li > div.kakao {
  padding-left: 22px;
  background: url(../images/kakao.jpg) top 4px left no-repeat;
  background-size: 16px;
}
.menu-list li > div.facebook {
  padding-left: 22px;
  background: url(../images/facebook.jpg) top 4px left no-repeat;
  background-size: 16px;
}
.menu-list li > div.naver {
  padding-left: 22px;
  background: url(../images/naver.jpg) top 4px left no-repeat;
  background-size: 16px;
}
.menu-list li > div.twitter {
  padding-left: 22px;
  background: url(../images/twitter.jpg) top 4px left no-repeat;
  background-size: 16px;
}
.menu-list li > div.apple {
  padding-left: 22px;
  background: url(../images/apple.png) top 4px left no-repeat;
  background-size: 16px;
}

.menu-list li > div.switch {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 0;
}
.point-txt {
  top: 11px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #999da4;
}
.popover-join p {
  text-align: center;
  margin-top: 12px;
}
.popover-join p span {
  text-decoration: underline;
}
.login-link {
  position: relative;
  width: calc(100% + 2rem);
  height: 60px;
  background: #f2f4f7;
  text-align: center;
  margin: 0 -1rem;
}
.SNS-link {
  text-align: center;
}
.SNS-link li {
  display: inline-block;
  vertical-align: middle;
  margin: 8px;
}
.SNS-link li a {
  display: block;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  font-size: 0;
}
.SNS-link a.kakao {
  background: url(../images/kakao.jpg) no-repeat;
  background-size: 100%;
}
.SNS-link a.naver {
  background: url(../images/naver.jpg) no-repeat;
  background-size: 100%;
}
.SNS-link a.facebook {
  background: url(../images/facebook.jpg) no-repeat;
  background-size: 100%;
}

.SNS-link a.apple {
  background: url(../images/apple.png) no-repeat;
  background-size: 100%;
}

.SNS-link a.twitter {
  background: url(../images/twitter.jpg) no-repeat;
  background-size: 100%;
}
.login-link p {
  line-height: 58px;
}
.login-link a {
  display: inline-block;
  margin-left: 8px;
}
.s-title {
  font-weight: 400;
  padding: 10px 0 0 0;
}
.s-title a {
  font-size: 12px;
  color: #999;
  text-decoration: underline;
}
.set-mobile {
  text-align: center;
  padding-top: 35px;
}
.set-mobile .label {
  display: inline-block;
  width: 100px;
  line-height: 25px;
  font-size: 12px;
  color: #999;
  border: solid 1px #b1b3b6;
  border-radius: 25px;
  margin-bottom: 12px;
}
.set-mobile p {
  margin: 27px 0 40px 0;
}
.account-del {
  height: calc(100vh - 46px);
  padding: 24px 0;
}
.account-del > p {
  line-height: 22px;
  margin-top: 16px;
}
.account-del .btn_group {
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: 24px;
  display: inline-flex;
}
.btn_group button + button {
  margin-left: 12px;
}
.popover-delaccount {
  text-align: center;
  padding: 0 0 20px 0;
}
.popover-delaccount > p {
  margin: 10px 0 20px 0;
}
.faq-select {
  padding: 8px 0;
}
.faq-select > button {
  border: solid 1px #b1b3b6;
  border-radius: 4px;
  width: 100%;
  line-height: 40px;
  font-weight: 400;
  text-align: left;
  padding: 0 12px;
  background: url(../images/ic_arrow_dropdown.svg) right 12px center no-repeat;
  background-size: 16px;
}
.polish-popup {
  padding: 14px 0;
  line-height: 22px;
}
.polish-popup h3 {
  font-size: 18px;
  margin-bottom: 20px;
}
.info-list {
  background: rgba(153, 157, 164, 0.1);
  margin-left: -16px;
  margin-right: -16px;
  padding: 0 16px;
}
.info-list li {
  padding: 16px 0;
  border-bottom: solid 1px #e0e1e3;
}
.info-list li > p {
  margin-top: 5px;
  font-weight: 400;
}
/* more------e*/

/* signup------s*/
.signup_email {
  padding: 24px 0 0 0;
}
.form-group {
  margin: 20px 0;
}
.form-box {
  display: flex;
  border-bottom: solid 1px #e0e1e3;
  padding: 12px 0;
  margin-bottom: 5px;
}
.form-group .point-txt-gray {
  margin-bottom: 12px;
}
.signup_email .d-grid {
  padding-top: 24px;
}

.sel-data {
  width: 100%;
  background: url(../images/ic_arrow_dropdown.svg) right center no-repeat;
  background-size: 16px;
  text-align: left;
  padding: 0;
}
.popover-join {
  padding-top: 20px;
}
.popover-join .link-copy {
  margin: 30px 0 16px 0;
  text-align: center;
}
.popover-email button {
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 400;
}
.popover-email button.focus {
  color: #ff3477;
}

.message-wrap {
  width: 100%;
  padding-top: 24px;
}
.msg-complete {
  width: 100%;
  padding: 64px 0;
  text-align: center;
}
.msg-complete::before {
  content: '';
  display: inline-block;
  width: 64px;
  height: 47px;
  margin-bottom: 42px;
  background: url(../images/ic_complete.svg) no-repeat;
}
.msg-complete h1 {
  margin-bottom: 30px;
}
.msg-complete p {
  line-height: 22px;
}
/* signup------e*/

/* login------s*/
.txt-link {
  margin-top: 24px;
}
.txt-link a {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  line-height: 24px;
  color: #ff3477;
}
.txt-link a + a::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 12px;
  background: #aaa;
  margin: 0 10px;
}
.time-check {
  margin-bottom: 75px;
}
/* login------e*/

/* my------s*/
.my-wrap > h2 {
  margin-top: 24px;
  margin-bottom: 5px;
}
.my-wrap > p {
  line-height: 18px;
  padding: 8px 0 4px 0;
}
.list-profile li {
  position: relative;
  text-align: right;
  padding: 12px 0;
}
.list-profile li::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: #e1e1e1;
  margin: 0 -16px;
}
.list-profile li:last-child::after {
  display: none;
}
.list-profile li > a {
  display: flex;
  align-items: flex-start;
  text-align: left;
}
.list-profile li > a > div:first-child {
  width: 107px;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;
}
.list-profile li > a > div:last-child {
  width: calc(100% - 80px);
  padding-left: 10px;
}
.list-profile li > a > div:last-child p {
  line-height: 18px;
  margin-top: 4px;
}
.list-profile li > button.btn-sm {
  width: 92px;
}
.list-profile li > button.btn-md {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 4px;
  color: #1d1d1d;
}
.getPoint-noti {
  display: block;
  background: rgba(153, 157, 164, 0.1);
  text-align: center;
  padding: 6px 0;
  margin-top: 16px;
}
.my-wrap .review-list {
  margin-top: 30px;
}
.my-wrap .review-list span.date {
  font-size: 12px;
  color: #999da4;
  margin-left: 5px;
}
.my-wrap .review-list h3 > a::after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background: url(../images/ic_arrow_link_black.svg) no-repeat;
  background-size: 100%;
}
.my-wrap .review-star {
  display: inline-block;
  margin-top: 0;
}
.my-wrap .review-star span {
  width: 12px;
  height: 12px;
  vertical-align: middle;
}
.my-wrap .review-list .txt-link {
  position: absolute;
  right: 0;
  top: 16px;
  color: #999da4;
  margin-top: 0;
}
.my-wrap .review-list .review-txt {
  margin-top: 12px;
}
.link-getReview a {
  display: block;
  border: solid 1px #ff3477;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  color: #ff3477;
  padding: 14px;
  background: url(../images/ic_arrow_link_pink.svg) right 12px center no-repeat;
}
.link-getReview {
  margin-top: 16px;
}
/* my------e*/

/* coinShop------s*/
.coinShop-wrap > h3 {
  margin-top: 24px;
}
.myCoin-box {
  border: solid 1px #3a3a3a;
  border-radius: 4px;
  padding: 0 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 74px;
  margin-top: 24px;
  margin-bottom: 20px;
}
.myCoin-box > p {
  text-align: right;
  line-height: 18px;
}
.myCoin-box .ic-coin {
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
}
.ic-coin {
  position: relative;
  display: block;
  line-height: 24px;
  padding-left: 30px;
}
.ic-coin::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background: url(../images/ic_coin.svg) no-repeat;
  background-size: 100%;
  margin-right: 5px;
}
.ic-call {
  position: relative;
  display: block;
  line-height: 24px;
  padding-left: 30px;
}
.ic-call::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background: url(../images/ic_call_line.svg) no-repeat;
  background-size: 100%;
  margin-right: 5px;
}
.coinList-title {
  display: flex;
  justify-content: space-between;
  color: #999da4;
  margin-bottom: 8px;
}
.coin-charge-list li {
  border-top: solid 1px #e1e1e1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}
.coin-charge-list li .ic-coin span {
  display: inline-block;
  vertical-align: middle;
}
.btn-aboutCoin {
  width: 100%;
  font-size: 14px;
  font-weight: 100;
  text-align: left;
  line-height: 22px;
  margin-bottom: 12px;
  background: rgba(153, 157, 164, 0.1) url(../images/ic_arrow_link.svg) right
    10px center no-repeat;
  padding: 10px;
}
.coin-caution {
  margin-top: 24px;
  font-size: 12px;
  line-height: 18px;
  color: #999da4;
}
.coin-caution h3 {
  margin-bottom: 8px;
}
.coin-caution h4 {
  font-size: 12px;
  line-height: 18px;
  color: #999da4;
  margin-bottom: 4px;
}
.coin-caution p {
  position: relative;
  padding-left: 10px;
}
.coin-caution p::before {
  content: '\B7 ';
  font-size: 16px;
  position: absolute;
  left: 0;
  top: 0;
}

.about-list {
  line-height: 22px;
  border-bottom: solid 1px #e1e1e1;
  margin-bottom: 24px;
  padding-bottom: 24px;
}
.about-list h3 {
  margin-bottom: 8px;
}
.about-list li {
  position: relative;
  padding-left: 10px;
}
.about-list li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 9px;
  background: #1d1d1d;
  width: 4px;
  height: 4px;
  border-radius: 50%;
}
.table-style {
  margin-top: 10px;
}
.table-style table {
  width: 100%;
}
.table-style thead {
  border-left: solid 1px #ff3477;
  border-right: solid 1px #ff3477;
}
.table-style th {
  background: #ff3477;
  color: #fff;
  font-size: 12px;
  font-weight: 100;
  line-height: 15px;
  height: 48px;
  text-align: center;
  vertical-align: middle;
}
.table-style tbody {
  border-left: solid 1px #e1e1e1;
  border-right: solid 1px #e1e1e1;
}
.table-style td {
  border-bottom: solid 1px #e1e1e1;
  height: 60px;
  text-align: center;
  vertical-align: middle;
}
.table-style td span {
  display: block;
}
.table-style td .sale {
  color: #e81649;
}
.coinInfo-list {
  margin-top: 24px;
}
.coinInfo-list dt {
  font-size: 12px;
  font-weight: 100;
  color: #999da4;
  padding-bottom: 8px;
}
.coinInfo-list dd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-top: solid 1px #e1e1e1;
}
.point-link {
  color: #999da4;
}
.point-link::after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background: url(../images/ic_arrow_link.svg) no-repeat;
  background-size: 100%;
  margin-left: 4px;
}
.pay-total {
  border-top: solid 1px #e1e1e1;
  margin: 30px 0;
}
.pay-total dt,
.pay-total dd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 14px;
}
.btn-payment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1.5px #1d1d1d;
  border-radius: 4px;
  padding: 15px;
}
.pay-total dd {
  color: #999da4;
}
.agree-chk {
  margin-top: 30px;
  margin-bottom: 18px;
}
.select-payment {
  margin-bottom: 35px;
}
.select-payment li {
  padding: 10px 0;
}
.point-use {
  font-size: 14px;
}
.point-use > h1 {
  margin: 16px 0 24px 0;
}
.input-point {
  font-size: 16px;
  font-weight: 100;
  border-bottom: solid 1px #eee;
  margin-bottom: 4px;
}
.input-point input {
  width: 100%;
  height: 48px;
}
.charge-message {
  text-align: center;
  padding: 40px 0 24px 0;
}
.charge-message h2 + p {
  margin-top: 6px;
}
.charge-detail {
  padding: 20px;
  border-top: solid 1px #e1e1e1;
}
.charge-detail dl {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 6px 0;
  line-height: 22px;
}
.charge-detail dd {
  text-align: right;
}
.charge-detail dl + p {
  margin-top: 8px;
}

.history-list li {
  border-bottom: solid 1px #e1e1e1;
}
.history-list a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0 16px 32px;
}
.history-list a > p:first-child {
  width: 200px;
}
.history-list a::before {
  top: 50%;
  transform: translateY(-50%);
}
.history-list a span {
  display: block;
  line-height: 20px;
  font-weight: 400;
}
.history-list a span.increase {
  color: #12b469;
}
.history-list a span.decrease {
  color: #e81649;
}
.history-detail dl {
  border-top: solid 1px #e1e1e1;
  margin-top: 20px;
}
.history-detail dt {
  font-size: 12px;
  font-weight: 100;
  line-height: 18px;
  color: #999da4;
  margin: 8px 0;
}
.history-detail dd > p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 20px;
  line-height: 20px;
}
.history-detail dd > p > span:last-child {
  text-align: right;
}
.history-detail dd button {
  margin-top: 20px;
}
/* coinShop------e*/

/* etc------s*/
.error-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}
.error-wrap h3 {
  margin-bottom: 10px;
}
.error-wrap button {
  margin-top: 35px;
}
.error-box {
  line-height: 20px;
  background: rgba(153, 157, 164, 0.1);
  padding: 12px 14px;
}
.error-box h4 {
  margin-bottom: 5px;
}
.error-box h4::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background: url(../images/ic_info.svg) no-repeat;
  background-size: 100%;
  margin-right: 5px;
}
.error-box a {
  display: block;
  text-align: center;
  line-height: 30px;
  border: solid 1px rgba(153, 157, 164, 0.3);
  border-radius: 4px;
  margin-top: 8px;
}
.error-retry {
  position: relative;
  margin: 20px auto;
  text-align: center;
}
.error-retry > button {
  width: 50px;
  padding: 0;
}
.error-retry > button::before {
  content: '';
  display: block;
  width: 48px;
  height: 48px;
  background: url(../images/ic_retry.svg) no-repeat;
  background-size: 100%;
  margin-bottom: 6px;
}
.root-layout {
  display: flex;
  justify-content: center;
}
.root-layout-inner {
  width: 512px;
}

.root-layout-inner.left {
  z-index: 1;
}
.root-layout-inner.left .fixed-wrapper {
  position: fixed;
}
.root-layout-inner.left .box {
  margin-top: 304px;
}
.root-layout-inner.left p {
  color: white;
  font-size: 24px;
  font-weight: 300;
}

.root-layout-inner.left .bi {
  width: 423px;
  margin-top: 24px;
}
.root-layout-inner.left .store-wrapper {
  width: 423px;
  margin-top: 66px;
  display: flex;
  justify-content: space-between;
}

.root-layout-inner.left .store {
  width: 204px;
}

.root-layout-inner.left .footer {
  position: fixed;
  bottom: 80px;
}
.root-layout-inner.left .footer p {
  font-size: 15px;
  line-height: 24px;
  color: white;
}

.root-layout-inner.left .footer a button {
  font-size: 18px;
  font-weight: 700;
  padding: 21px 42px;
  border-radius: 20px;
  color: #4d1b78;
  margin-top: 20px;
  background-color: white;
}

.root-layout-inner.right {
  position: relative;
}
.fixed {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.root-layout-bg {
  background: linear-gradient(313.68deg, #541b86 20.67%, #e60044 77%);
}

.root-layout-bg-image {
  background-image: url(../images/pc-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.4;
}
@media only screen and (min-width: 1024px) {
  .wrapper {
    max-width: 430px;
    margin: 0px;
    position: relative;
  }
  .header.home,
  .applyBar,
  .nav-bar,
  .offcanvas,
  .header {
    max-width: 430px;
    left: calc(50% + 215px);
  }
}

@media only screen and (max-width: 1024px) {
  .root-layout-inner.left {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .root-layout-bg,
  .root-layout-bg-image {
    display: none;
  }
}

@media only screen and (max-height: 900px) {
  .root-layout-inner.left .footer {
    position: static;
    margin-top: 120px;
  }
}

@media only screen and (max-height: 1080px) {
  .root-layout-inner.left .box {
    margin-top: 180px;
  }
}
