@charset "UTF-8";

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 100;
    src: local("Noto Sans Regular"),local("NotoSansKR-Regular"),url("../fonts/NotoSansKR-Regular.eot");
    src: url("../fonts/NotoSansKR-Regular.eot?#iefix") format("embedded-opentype"),url("../fonts/NotoSansKR-Regular.woff2") format("woff2"),url("../fonts/NotoSansKR-Regular.woff") format("woff")
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src: local("Noto Sans Medium"),local("NotoSansKR-Medium"),url("../fonts/NotoSansKR-Medium.eot");
    src: url("../fonts/NotoSansKR-Medium.eot?#iefix") format("embedded-opentype"),url("../fonts/NotoSansKR-Medium.woff2") format("woff2"),url("../fonts/NotoSansKR-Medium.woff") format("woff")
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    src: local("Noto Sans Bold"),local("NotoSansKR-Bold"),url("../fonts/NotoSansKR-Bold.eot");
    src: url("../fonts/NotoSansKR-Bold.eot?#iefix") format("embedded-opentype"),url("../fonts/NotoSansKR-Bold.woff2") format("woff2"),url("../fonts/NotoSansKR-Bold.woff") format("woff")
}

* {letter-spacing: -0.5px; box-sizing: border-box;}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
    text-decoration: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
    font-family:'Noto Sans KR',Dotum,'돋움',Gulim,'굴림',Malgun Gothic,Helvetica,AppleGothic,'애플고딕',sans-serif,Tahoma;
    font-size: 14px;
    font-weight: 100;
    color: #1d1d1d;
	line-height: 1;
}
ol, ul {
	list-style: none;
}
pre {
    display: block;
    font-family:'Noto Sans KR';
    white-space:pre-line;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
img {width: 100%; border: 0;}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
input {border:0; padding: 0;}
input:focus {outline: none;}
input::placeholder {
color: #d6d8da;
opacity: 1; /* 파이어폭스에서 뿌옇게 나오는 현상을 방지하기 위한 css */
}
input::-webkit-input-placeholder {
color: #d6d8da;
}
input:-ms-input-placeholder {
color: #d6d8da;
}
input:-mos-input-placeholder {
color: #d6d8da;
}

textarea::placeholder {
color: rgba(29, 29, 29, .3);
opacity: 1;
}
textarea::-webkit-input-placeholder {
color: rgba(29, 29, 29, .3);
}
textarea:-ms-input-placeholder {
color: rgba(29, 29, 29, .3);
}
textarea:-mos-input-placeholder {
color: rgba(29, 29, 29, .3);
}

button {border:0;background: transparent;}
a {color: #1d1d1d; background-color: transparent;}
a:active,a:hover {color: #1d1d1d; outline: 0;}
h1 {font-size: 24px;line-height: 32px;font-weight: 700;color: #1d1d1d;}
h2 {font-size: 20px;line-height: 32px;font-weight: 700;color: #1d1d1d;}
h3 {font-size: 16px;line-height: 24px;font-weight: 400;color: #1d1d1d;}
h4 {font-size: 14px;line-height: 20px;font-weight: 400;color: #1d1d1d;}
h5 {font-size: 12px;line-height: 18px;font-weight: 100;color: #1d1d1d;}
